import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../../GlobalComponents/Footer";
import Navbar from "../../Navbar";
import { repsBookingService } from "../../../../../services/repsBooking.services";
import Select from "react-select";
import useDebounce from "../../../../../helpers/UseDebounce";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import CancelModal from "../Modal/Cancle";
import moment from "moment";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import Norecord from "./Norecord";
import { ExportSheet } from "react-xlsx-sheet";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { UncontrolledTooltip } from "reactstrap";
import { EquipmentUsedReportService } from "../../../../../services/equipmentUsedReport.services";

const statusOptions = [
  { value: "", label: "All" },
  { value: "yes", label: "Filled" },
  { value: "no", label: "Empty" },
];
const pastYearDate = moment().subtract(6, "months").format("YYYY-MM-DD");
const yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");
const searchFromDate = new Date(pastYearDate);
const searchToDate = new Date(yesterdayDate);

const RepsItemUsedList = () => {
  const [redirect, setRedirect] = useState(false);
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");
  const [search, setSearch] = useState("");
  const [filterOperationFromDate, setfilterOperationFromDate] =
    useState(pastYearDate);
  const [filterOperationToDate, setfilterOperationToDate] =
    useState(yesterdayDate);
  const [searchOperationFromDate, setSearchOperationFromDate] =
    useState(searchFromDate);
  const [searchOperationToDate, setSearchOperationToDate] =
    useState(searchToDate);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([
    { value: "", label: "All" },
  ]); //{ value: "", label: "All" }
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelId, setCancelId] = useState(0);
  const [firstLoad, setFirstLoad] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataXlsx, setDataXlsx] = useState([]);
  console.log(setLoading, setDataXlsx);
  const head = [
    { title: "BOOKING ID", dataIndex: "BOOKING ID" },
    { title: "HOSPITAL NAME", dataIndex: "HOSPITAL NAME" },
    { title: "SURGEON NAME", dataIndex: "SURGEON NAME" },
    { title: "OPERATION NAME", dataIndex: "OPERATION NAME" },
    { title: "OPERATION DATE", dataIndex: "OPERATION DATE" },
    { title: "PRODUCT CODE", dataIndex: "PRODUCT CODE" },
    { title: "PRODUCT DESCRIPTION", dataIndex: "PRODUCT DESCRIPTION" },
    { title: "BRAND", dataIndex: "BRAND" },
    { title: "QUANTITY", dataIndex: "QUANTITY" },
    { title: "LOT NUMBER", dataIndex: "LOT NUMBER" },
    { title: "USED/IMPLANTED", dataIndex: "USED/IMPLANTED" },
    { title: "OUTCOME OF THE CASE ", dataIndex: "OUTCOME OF THE CASE " },
    { title: "NOTES", dataIndex: "NOTES" },
  ];

  const getXlsxData = async () => {
    setIsLoading(true);
    try {
    const res = await EquipmentUsedReportService.getItemUsedExport({
      working_delivery_from_date: "",
      type: ["loan"],
      status: ["pending", "booked", "dispatch", "return", "close"],
      operation_from_date: filterOperationFromDate,
      operation_to_date: filterOperationToDate,
      search: search,
      page: activePage,
      limit: "",
      reps_id: user?.data?._id,
      itemUsedProduct_filter: status,
    });
    if (res.status) {
    showXlsData(res?.data);
    }
    } catch (error) {
    console.error("Error: ", error);
    }
  };

  async function showXlsData(data) {
    console.log("data xl", data);
    const workbook = new ExcelJS.Workbook();
    const ws = workbook.addWorksheet("Item Used Report");
    const header1 = [
      "BOOKING ID",
      "HOSPITAL",
      "SURGEON NAME",
      "OPERATION NAME",
      "OPERATION DATE",
      "PRODUCT CODE",
      "PRODUCT DESCRIPTION",
      "BRAND",
      "QUANTITY",
      "LOT NUMBER",
      "USED/IMPLANTED",
      "OUTCOME OF THE CASE",
      "NOTES",
    ];
    
    const header1Row = ws.addRow(header1);
    header1Row.eachCell((cell) => {
      cell.font = { bold: true, size: 11 };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCC0DA" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });
  
    if (data?.length) {
      let rowIndex = 2; 
      
      data.forEach((item) => {
        const rowData = [
          item?.bookings?.booking_id,
          item?.hospitalData[0]?.name,
          `${item?.bookingSurgeonData[0]?.SurgeonDetail[0].first_name} ${item?.bookingSurgeonData[0]?.SurgeonDetail[0].last_name}`,
          item.bookings.operation_name,
          item.bookings?.operation_date ? moment(item.bookings.operation_date).format("ddd, MMMM Do, YYYY") : "",
        ];
  
        const productDetails = item.itemUsedProducts || [];
       
        // If there are product details, add them
        if (productDetails.length > 0) {
          productDetails.forEach((product, index) => {
            console.log("product", product);
            const productRow = [
              index === 0 ? rowData[0] : "", 
              index === 0 ? rowData[1] : "",
              index === 0 ? rowData[2] : "",
              index === 0 ? rowData[3] : "",
              index === 0 ? rowData[4] : "",
              product.productData.manufacturers_product_code,
              product.productData.name,
              product.productData.product_brand,
              product.quantity_of_used_item,
              product.lot_number,
              product.item_in_used ? "Yes" : "No",
              item?.bookingProductsReviews[0]?.review || "",
              item?.bookingProductsReviews[0]?.description || "",
            ];
            ws.addRow(productRow);
            rowIndex += 1;
          });
  
          // Apply merging for multi-product rows for the current booking
          if (productDetails.length > 1) {
            ws.mergeCells(rowIndex - productDetails.length, 1, rowIndex - 1, 1); // BOOKING ID
            ws.mergeCells(rowIndex - productDetails.length, 2, rowIndex - 1, 2); // HOSPITAL
            ws.mergeCells(rowIndex - productDetails.length, 3, rowIndex - 1, 3); // SURGEON NAME
            ws.mergeCells(rowIndex - productDetails.length, 4, rowIndex - 1, 4); // OPERATION NAME
            ws.mergeCells(rowIndex - productDetails.length, 5, rowIndex - 1, 5); // OPERATION DATE
            ws.mergeCells(rowIndex - productDetails.length, 12, rowIndex - 1, 12); // OUTCOME OF THE CASE
            ws.mergeCells(rowIndex - productDetails.length, 13, rowIndex - 1, 13); // NOTES
          }
        } else {
          // If no product details, add a row with only the booking data
          const productRow = [
            rowData[0],
            rowData[1],
            rowData[2],
            rowData[3],
            rowData[4],
            "", // PRODUCT CODE
            "", // PRODUCT DESCRIPTION
            "", // BRAND
            "", // QUANTITY
            "", // LOT NUMBER
            "", // USED/IMPLANTED
            item.outcome_of_the_case || "",
            item.notes || "",
          ];
          ws.addRow(productRow);
          rowIndex += 1;
        }
      });
  
      ws.columns.forEach((col) => (col.width = 25));
      ws.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
          row.eachCell((cell) => {
            cell.border = {
              top: { style: "thin", color: { argb: "000000" } },
              left: { style: "thin", color: { argb: "000000" } },
              bottom: { style: "thin", color: { argb: "000000" } },
              right: { style: "thin", color: { argb: "000000" } },
            };
            cell.alignment = {
              vertical: "middle",
              horizontal: "left",
              wrapText: true,
            };
          });
        }
      });
  
      const excelBlob = await workbook.xlsx.writeBuffer();
      const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      );
  
      const link = document.createElement("a");
      link.href = excelUrl;
      link.download = "Item Used Report.xlsx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(excelUrl);
      document.body.removeChild(link);
      setIsLoading(false);
    }
  }
  
  const handleSearch = (val) => {
    setSearch(val);
    setActivePage(1);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    if (pageNumber !== activePage) {
      setLoading(true);
      window.scrollTo({ top: 0 });
    }
  };

  const getList = async () => {
    setBookingList([]);
    setLoading(true);
    setFirstLoad(1);
    try {
      const res = await repsBookingService.getBookingForDashboard({
        working_delivery_from_date: "",
        type: ["loan"],
        status: ["pending", "booked", "dispatch", "return", "close"],
        operation_from_date: filterOperationFromDate,
        operation_to_date: filterOperationToDate,
        search: search,
        page: activePage,
        limit: 50,
        reps_id: user?.data?._id,
        itemUsedProduct_filter: status,
      });
      setBookingList(res.data);
      setTotalItemsCount(res.total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchOperationFromDate = (val) => {
    setActivePage(1);
    setSearchOperationFromDate(val != null ? new Date(val) : "");
    setfilterOperationFromDate(
      val != null ? moment(val).format("YYYY-MM-DD") : ""
    );
  };

  const handleSearchOperationToDate = (val) => {
    setActivePage(1);
    setSearchOperationToDate(val != null ? new Date(val) : "");
    setfilterOperationToDate(
      val != null ? moment(val).format("YYYY-MM-DD") : ""
    );
  };

  const handleStatus = (e) => {
    setActivePage(1);
    setStatus(e.value);
    setSelectedStatus(e);
  };

  const cancelModalOpen = (id) => {
    setCancelId(id);
    setCancelModal(true);
  };

  const cancelModalClose = () => {
    setCancelId(0);
    setCancelModal(false);
  };

  const refreshGetList = () => {
    setCancelId(0);
    setCancelModal(false);
    setActivePage(1);
    setLoading(true);
    window.scrollTo({ top: 0 });
    if (activePage == 1) {
      getList();
    }
  };

  useDebounce(
    () => {
      if (firstLoad != 0) getList();
    },
    [search],
    800
  );

  useEffect(() => {
    getList();
  }, [activePage, status, filterOperationFromDate, filterOperationToDate]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  const handleFocus = () => {
    setRedirect(true);
  };

  if (redirect) {
    return <Redirect to="/reps/item-used/all" />;
  }
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  if (!user || user.data.user_role !== "reps") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Navbar activePage="item-used" />
      <main className="offset">
        <div className="container-fluid">
          <div className="row pt-4">
            {/* <div className="col-lg-2 side-filters">
<span className="side-heading">Dashboard</span>
</div> */}

            <div className="col-lg-12">
              <div className="ground">
                <div className="row">
                  <div className="col-lg-4 col-md-12 mb-2">
                    <div className="sub-menu mb-0 mb-sm-3">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <Link
                          type="button"
                          className="btn btn-secondary "
                          to="/reps/item-used"
                        >
                          {"Today"}
                        </Link>
                        <Link
                          type="button"
                          className="btn btn-secondary mx-1"
                          to="/reps/item-used/tomorrow"
                        >
                          {"Tomorrow"}
                        </Link>

                        <Link
                          type="button"
                          className="btn btn-secondary btn-disabled active"
                          to="/reps/item-used/past"
                        >
                          {"Past Bookings"}
                        </Link>

                        <Link
                          type="button"
                          className="btn btn-secondary mx-1"
                          to="/reps/item-used/all"
                        >
                          {"All Bookings"}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 mb-2">
                    <div className="form-search">
                      <Input
                        className="form-control mr-sm-2"
                        type="text"
                        placeholder="Search.."
                        value={search}
                        onFocus={handleFocus}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-5 col-sm-6 mb-2">
                    {/* <div className="d-flex justify-content-end" style={{gap:"10px"}}> */}
                    {/* icon code required */}
                    <div className="">
                      <React.Fragment>
                        <div className="row no-gutters mb-2">
                          <div className="col-lg-4 col-sm-4 col-6 px-0">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              showClearButton={false}
                              placeholderText="From"
                              selected={searchOperationFromDate}
                              onChange={(e) => handleSearchOperationFromDate(e)}
                              //onKeyDown={handleclearFromDate}
                              className="form-control"
                              disabled={!loading ? false : true}
                              customInput={
                                <MaskedInput
                                  pipe={autoCorrectedDatePipe}
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]}
                                  keepCharPositions={true}
                                  guide={true}
                                  showMask={false}
                                />
                              }
                            />
                          </div>

                          <div className="col-lg-4 col-sm-4 col-6 mb-2 px-1">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              showClearButton={false}
                              placeholderText="To"
                              selected={searchOperationToDate}
                              onChange={(e) => handleSearchOperationToDate(e)}
                              //onKeyDown={handleclearToDate}
                              className="form-control"
                              disabled={!loading ? false : true}
                              customInput={
                                <MaskedInput
                                  pipe={autoCorrectedDatePipe}
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]}
                                  keepCharPositions={true}
                                  guide={true}
                                  showMask={false}
                                />
                              }
                            />
                          </div>

                          <div className="col-lg-3 col-sm-4 col-12 px-1">
                            <div className="label_adjust">
                              <label className="small mb-0">Item Used </label>
                              <Select
                                placeholder="Item Used"
                                value={selectedStatus}
                                onChange={handleStatus}
                                options={statusOptions}
                                className="text-left"
                              />
                            </div>
                          </div>
                          <div>
                    <button
                      // style={{ marginTop: "40px" }}
                      className={
                        !isLoading && !loading
                          ? "btn btn-default add_print ml-3"
                          : "btn btn-default add_print ml-3 dropdown-disabled"
                      }
                      onClick={() =>
                        !isLoading && !loading && bookingList?.length
                          ? getXlsxData()
                          : ""
                      }
                      id="ReportDownload"
                    >
                      <i className="fa fa-print"></i>
                    </button>
                  </div>
                  <UncontrolledTooltip target={"ReportDownload"}>
                    Print
                  </UncontrolledTooltip>
                  {!loading && !isLoading ? (
                    ""
                  ) : (
                    <UncontrolledTooltip target={"ReportDownload"}>
                      {loading
                        ? "Please wait"
                        : isLoading
                        ? "Please wait"
                        : "No Record Found"}
                    </UncontrolledTooltip>
                  )}

                  {dataXlsx && (
                    <ExportSheet
                      header={head}
                      fileName={"item used"}
                      dataSource={dataXlsx}
                      xlsx={XLSX}
                    >
                      <span></span>
                    </ExportSheet>
                  )}
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
                <div className="table-scroll mt-4">
                  <Table className="table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            minWidth: "200px",
                          }}
                        >
                          Booking #
                        </th>
                        <th scope="col">Hospital Name </th>
                        <th scope="col">Operation Name </th>
                        <th scope="col" className="mobilehide">
                          Kits Required{" "}
                        </th>
                        <th scope="col">Sets </th>
                        <th scope="col">Item Used </th>
                        <th scope="col" className="text-manage">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingList &&
                        bookingList.map((item, index) => (
                          <tr key={item._id + "-" + index}>
                            <td
                              width="200"
                              data-th="Booking #"
                              className=""
                            >
                              <Link
                                to={
                                  "/reps/booking/details/" + item.bookings._id
                                }
                              >
                                <span>
                                  <strong> {item.bookings.booking_id} </strong>
                                </span>
                              </Link>
                            </td>
                            <td
                              className=""
                              data-th="Hospital Name"
                            >
                              {item.hospitalId.name}
                            </td>
                            <td
                              data-th="Operation Name"
                              className=""
                            >
                              <div>{item.bookings.operation_name}</div>
                            </td>

                            <td
                              data-th="Kits Required"
                              className="mobilehide"
                            >
                              <small>
                                {item.bookingkits &&
                                  item.bookingkits.map((kit, ind) => (
                                    <React.Fragment key={ind}>
                                      <div>
                                        <span>{kit.kits[0].name} </span>
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </small>
                            </td>
                            <td
                              data-th="Sets"
                              className="mobilehide"
                            >
                              <small>
                                {item.bookingKitSetId &&
                                  item.bookingKitSetId.map((kset, ind) => (
                                    <React.Fragment key={ind}>
                                      <div>
                                        <span>{kset.setId[0].set_name} </span>
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </small>
                            </td>

                            <td
                             
                              data-th="Filled Status"
                            >
                              <span
                                className={
                                  "capsule " +
                                  (item.itemUsedProducts.length > 0
                                    ? "green-dim "
                                    : "red-dim ") +
                                  " mr-2 mb-1"
                                }
                              >
                                {item.itemUsedProducts.length > 0
                                  ? "Filled"
                                  : "Empty"}
                              </span>
                            </td>

                            <td
                              className="text-manage"
                            >
                              <div className="action-area dropdown">
                                <div className="mb-2">
                                  <span
                                    className={
                                      "capsule " +
                                      ((item.bookings.status == "booked" &&
                                        dateFormat(
                                          item.bookings.working_delivery_date,
                                          "yyyy-mm-dd"
                                        ) !=
                                          dateFormat(
                                            new Date(),
                                            "yyyy-mm-dd"
                                          )) ||
                                      item.bookings.status === "close" ||
                                      (item.bookingcollections.length &&
                                        item.bookingreturns.length === 0) ||
                                      (item.bookings.status == "dispatch" &&
                                        item.bookingcollections.length === 0 &&
                                        item.bookingreturns.length === 0)
                                        ? "green-dim "
                                        : "red-dim ") +
                                      " mr-2 mb-1"
                                    }
                                  >
                                    {item.bookings.status == "dispatch" && (
                                      <div>
                                        {item.bookingcollections.length &&
                                        item.bookingreturns.length === 0
                                          ? "Collected"
                                          : item.bookingreturns.length > 0
                                          ? "Attempting Return (Partial)"
                                          : "Dispatched"}
                                      </div>
                                    )}
                                    {item.bookings.status != "dispatch" &&
                                      capitalize(
                                        item.bookings.status === "booked" &&
                                          dateFormat(
                                            item.bookings.working_delivery_date,
                                            "yyyy-mm-dd"
                                          ) ===
                                            dateFormat(new Date(), "yyyy-mm-dd")
                                          ? "Pending Dispatch"
                                          : item.bookings.status === "booked"
                                          ? "Booked and Confirmed"
                                          : item.bookings.status === "pending"
                                          ? "Booked and not confirmed"
                                          : item.bookings.status === "close"
                                          ? "Fully Return"
                                          : item.bookings.status
                                      )}
                                  </span>
                                </div>

                                <div className="mb-2">
                                  <span>
                                    <Link
                                      className="btn btn-outline-primary btn-sm1"
                                      to={
                                        "/reps/booking/details/" +
                                        item.bookings._id
                                      }
                                    >
                                      More Details
                                    </Link>
                                  </span>
                                </div>

                                {(item.bookings.status === "pending" ||
                                  item.bookings.status === "booked") && (
                                  <div className="mb-2">
                                    <span>
                                      <Link
                                        className="btn btn-sm1 btn-outline-primary"
                                        to={
                                          "/reps/booking/edit/" +
                                          item.bookings._id
                                        }
                                      >
                                        Edit
                                      </Link>
                                    </span>
                                  </div>
                                )}

                                {item.bookings.status === "pending" && (
                                  <div className="mb-2">
                                    <span>
                                      <a
                                        href="javascript:;"
                                        onClick={() =>
                                          cancelModalOpen(item.bookings._id)
                                        }
                                        className="btn btn-outline-primary btn-sm1"
                                      >
                                        Cancel
                                      </a>
                                    </span>
                                  </div>
                                )}

                                {item.bookings.status === "booked" &&
                                  dateFormat(
                                    item.bookings.delivery_date,
                                    "yyyy-mm-dd"
                                  ) > dateFormat(new Date(), "yyyy-mm-dd") && (
                                    <div className="mb-2">
                                      <span>
                                        <a
                                          href="javascript:;"
                                          onClick={() =>
                                            cancelModalOpen(item.bookings._id)
                                          }
                                          className="btn btn-outline-primary btn-sm1"
                                        >
                                          Cancel
                                        </a>
                                      </span>
                                    </div>
                                  )}

                                {item.bookings.status == "dispatch" && (
                                  <div className="mb-2">
                                    <span>
                                      <Link
                                        className="btn btn-outline-primary btn-sm1"
                                        to={
                                          "/reps/booking/item-used/o/" +
                                          item.bookings._id
                                        }
                                      >
                                        Item Used
                                      </Link>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}

                      {loading == true && <Norecord />}

                      {bookingList &&
                        bookingList.length == 0 &&
                        firstLoad != 0 &&
                        loading == false && (
                          <tr className="text-center">
                            <td colSpan="7">No Record Found</td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                </div>
              </div>

              {bookingList && bookingList.length > 0 && (
                <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={50}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center mb-5"
                    activeLinkClass="active"
                    nextPageText="Next"
                    prevPageText="Prev"
                  />
                  <br className="d-block d-sm-none" />
                  <br className="d-block d-sm-none" />
                </div>
              )}

              {/* Dispatch Details Modal */}
            </div>
          </div>
          {/*<!--/row-->*/}
        </div>
      </main>

      <CancelModal
        cancelId={cancelId}
        cancelModal={cancelModal}
        cancelModalClose={cancelModalClose}
        refreshGetList={refreshGetList}
      />
      <Footer />
    </div>
  );
};
export default RepsItemUsedList;
