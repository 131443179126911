import React, { Component } from "react";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import { connect } from "react-redux";
import CacheLoading from "./CacheLoading";
// import  version  from "../package.json"; // Current version from package.json
import { Router, Route } from "react-router-dom";
import Home from "./components/Home/Index";
import Varify from "./components/Home/Varify";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import AdminDashboard from "./components/Users/Admin/Dashboard/DispatchDashboard";
import BookingTransfer from "./components/Users/Admin/Dashboard/BookingTransfer";
import TomorrowConsignmentDispatch from "./components/Users/Admin/Dashboard/Consignment/Dispatch/Tomorrow";
import PastConsignmentDispatch from "./components/Users/Admin/Dashboard/Consignment/Dispatch/PastBooking";
import UnprocessedConsignmentDispatch from "./components/Users/Admin/Dashboard/Consignment/Dispatch/Unprocessed";
import TodayConsignmentDispatch from "./components/Users/Admin/Dashboard/Consignment/Dispatch/Index";
import TodayBookingDispatch from "./components/Users/Admin/Dashboard/Dispatch/Index";
import TomorrowBookingDispatch from "./components/Users/Admin/Dashboard/Dispatch/Tomorrow";
import PastBookingDispatch from "./components/Users/Admin/Dashboard/Dispatch/PastBooking";
import UnprocessedBookingDispatch from "./components/Users/Admin/Dashboard/Dispatch/Unprocessed";
import AdminTodayBookingReturn from "./components/Users/Admin/Dashboard/Return/Today/Index";
import AdminTomorrowBookingReturn from "./components/Users/Admin/Dashboard/Return/Tomorrow/Index";
import AdminPastBookingReturn from "./components/Users/Admin/Dashboard/Return/Past/Index";
import UnprocessedBookingReturn from "./components/Users/Admin/Dashboard/Return/Unprocessed/Index";
import AdminTodayConsignmentReturn from "./components/Users/Admin/Dashboard/Consignment/Return/Today/Index";

import AdminTomorrowConsignmentCollection from "./components/Users/Admin/Dashboard/Consignment/Collection/Tomorrow/Index";
import AdminPastConsignmentCollection from "./components/Users/Admin/Dashboard/Consignment/Collection/Past/Index";
import UnprocessedConsignmentCollection from "./components/Users/Admin/Dashboard/Consignment/Collection/Unprocessed/Index";
import AdminPastConsignmentReturn from "./components/Users/Admin/Dashboard/Consignment/Return/Past/Index";
import UnprocessedConsignmentReturn from "./components/Users/Admin/Dashboard/Consignment/Return/Unprocessed/Index";
import AdminTomorrowConsignmentReturn from "./components/Users/Admin/Dashboard/Consignment/Return/Tomorrow/Index";
import AdminTodayConsignmentCollection from "./components/Users/Admin/Dashboard/Consignment/Collection/Today/Index";
import AdminTodayBookingCollection from "./components/Users/Admin/Dashboard/Collection/Today/Index";
import AdminTomorrowBookingCollection from "./components/Users/Admin/Dashboard/Collection/Tomorrow/Index";
import AdminPastBookingCollection from "./components/Users/Admin/Dashboard/Collection/Past/Index";
import UnprocessedBookingCollection from "./components/Users/Admin/Dashboard/Collection/Unprocessed/Index";

import NewAdminBookingRequest from "./components/Users/Admin/Dashboard/NewBookingRequest";
import AdminKits from "./components/Users/Admin/Kits/Kits";
import AdminSets from "./components/Users/Admin/Sets/Index";
import AdminSetsEdit from "./components/Users/Admin/Sets/EditSet";
import AdminProducts from "./components/Users/Admin/Products/Index";
import AdminCategories from "./components/Users/Admin/Kits/index";
import AdminUsers from "./components/Users/Admin/Users/Index";
import AdminHospital from "./components/Users/Admin/Hospitals/Index";
import AdminBooking from "./components/Users/Admin/Booking-Optimization/Index";
import AdminBookingClose from "./components/Users/Admin/Booking-Optimization/Close/Index";
import AdminBookingCancel from "./components/Users/Admin/Booking-Optimization/Cancel/Index";
import AdminAddBooking from "./components/Users/Admin/Booking/Add";
import AdminEditBooking from "./components/Users/Admin/Booking/Edit";
import AdminDetailsBooking from "./components/Users/Admin/Booking/Details";
import AdminBookingConsignment from "./components/Users/Admin/Booking-Optimization/Consignment/Open/Index";
import AdminBookingCloseConsignment from "./components/Users/Admin/Booking-Optimization/Consignment/Close/Index";
import AdminAvailability from "./components/Users/Admin/Availability/Index";
import AdminSurgeon from "./components/Users/Admin/Surgeon/Index";
import AdminItemUsed from "./components/Users/Admin/Booking/ItemUsed";

import AdminReportConsignment from "./components/Users/Admin/Reports/Consignment";
import AdminReportHistorical from "./components/Users/Admin/Reports/Historical";

import RepsDashboard from "./components/Users/Reps/Dashboard/Index";
import OpenBooking from "./components/Users/Reps/Booking/Index";
import ClosedBooking from "./components/Users/Reps/Booking/Close";
import ConsignmentBooking from "./components/Users/Reps/Booking/Consignment";
import RejectBooking from "./components/Users/Reps/Booking/Reject";
import CancelBooking from "./components/Users/Reps/Booking/Cancel";
import AddBooking from "./components/Users/Reps/Booking/Add";
import EditBooking from "./components/Users/Reps/Booking/Edit";
import DetailsBooking from "./components/Users/Reps/Booking/Details";
import RepHospital from "./components/Users/Reps/Hospitals/Index";
import RepSurgeon from "./components/Users/Reps/Surgeon/Index";
import RepsItemUsed from "./components/Users/Reps/Booking/ItemUsed";
import RepsItemUsedList from "./components/Users/Reps/ItemUsed/Index";
import RepsAllItemUsedList from "./components/Users/Reps/ItemUsed/All/Index";
import RepsTomorrowItemUsedList from "./components/Users/Reps/ItemUsed/Tomorrow/Index";
import RepsPastItemUsedList from "./components/Users/Reps/ItemUsed/Past/Index";
import ChangePassword from "./components/Users/Reps/User/ChangePassword";
import EditProfile from "./components/Users/Reps/User/EditProfile";
import { history } from "./helpers";
import { alertActions } from "./actions";
import HolidayList from "./components/Users/Admin/Booking/holidayList";
import Operation from "./components/Users/Admin/Operation/Index";
import RepsOperation from "./components/Users/Reps/Operation/Index";
import EquipmentUsedReport from "./components/Users/Admin/Dashboard/EquipmentUsedReport";

import AdminArchive from "./components/Users/Admin/Users/archive";
import AdminBookingBooking from "./components/Users/Admin/Booking-Optimization/Booking/booking";
import EditConsignmentRequest from "./components/Users/Reps/ConsignmentRequest/Edit/Index";
import AddConsignmentRequest from "./components/Users/Reps/ConsignmentRequest/Add/Index";
import ConsignmentRequest from "./components/Users/Reps/ConsignmentRequest/Index";
import PendingConsignmentRequest from "./components/Users/Admin/PendingConsignmentRequest/Index";
import RejectedBooking from "./components/Users/Reps/ConsignmentRequest/Rejected";
import CancelledBooking from "./components/Users/Reps/ConsignmentRequest/Cancelled";
import RejectConsignmentRequest from "./components/Users/Admin/PendingConsignmentRequest/Reject";
import DetailPage from "./components/Users/Reps/ConsignmentRequest/Detail/Index";
import BookingReview from "./components/Users/Admin/PendingConsignmentRequest/Detail/Index";
import CloseConsignmentRequest from "./components/Users/Admin/PendingConsignmentRequest/Close";
import DeactivatedUsersTab from "./components/Users/Admin/Users/deactivated";
import CacheBuster from "react-cache-buster";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration";

// import MyComponent from "./test";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVersion: "",
      newItem: "",
      editItem: null,
      isProduction: process.env.NODE_ENV === "production",
      isRefresh:false

    };
    this.ws = null;
    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      console.log(location, action,"00000000000000000");
      this.onRouteChange(location, action);
      dispatch(alertActions.clear());
    });
  }

  onRouteChange = (location, action) => {
    console.log("Route changed to:",action, location.pathname);
    this.checkForUpdates(); // Call the custom function on route change
  };
 
 


componentDidMount(){
  fetch("/meta.json")
  .then(response => response.json())
  .then(meta => {
    this.setState({currentVersion:meta.version});
    ServiceWorkerRegistration.register(); 
  })
  .catch(error => {
    console.error("Error loading meta.json:", error);
  });
}

  checkForUpdates = async () => {
    try {
      const res = await fetch("/meta.json");
      const meta = await res.json();

      if (meta.version !== this.state.currentVersion && this.state.currentVersion!="") {
        this.setState({version:meta.version});
        window.location.reload();
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
    }
  };

   
        render() {
    //const login = localStorage.getItem("isLoggedIn");
    return (
     
      <div>
    <CacheBuster
    currentVersion={this.state.currentVersion}
    isEnabled={this.state.isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<CacheLoading />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
  >
        <Router history={history}>
          
          <div>
            <Route exact path="/" component={Home} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/password/reset/:token"
              component={ResetPassword}
            />
            <Route path="/user/verify/:id/:token" component={Varify} />
            <Route exact path="/admin/dashboard" component={AdminDashboard} />
            <Route
              exact
              path="/admin/deactivated"
              component={DeactivatedUsersTab}
            />

            <Route
              exact
              path="/admin/dashboard/equipment-used-report"
              component={EquipmentUsedReport}
            />
            <Route
              exact
              path="/admin/dashboard/booking-dispatch"
              component={TodayBookingDispatch}
            />
            <Route
              exact
              path="/admin/dashboard/booking-dispatch/tomorrow"
              component={TomorrowBookingDispatch}
            />
            <Route
              exact
              path="/admin/dashboard/booking-dispatch/past"
              component={PastBookingDispatch}
            />
            <Route
              exact
              path="/admin/dashboard/booking-dispatch/unprocessed"
              component={UnprocessedBookingDispatch}
            />

            <Route
              exact
              path="/admin/dashboard/booking-returns"
              component={AdminTodayBookingReturn}
            />
            <Route
              exact
              path="/admin/dashboard/booking-returns/tomorrow"
              component={AdminTomorrowBookingReturn}
            />
            <Route
              exact
              path="/admin/dashboard/booking-returns/past"
              component={AdminPastBookingReturn}
            />
            <Route
              exact
              path="/admin/dashboard/booking-returns/unprocessed"
              component={UnprocessedBookingReturn}
            />

            <Route
              exact
              path="/admin/dashboard/consignment-dispatch/tomorrow"
              component={TomorrowConsignmentDispatch}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-dispatch/past"
              component={PastConsignmentDispatch}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-dispatch/unprocessed"
              component={UnprocessedConsignmentDispatch}
            />

            <Route
              exact
              path="/admin/dashboard/consignment-returns/past"
              component={AdminPastConsignmentReturn}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-returns/unprocessed"
              component={UnprocessedConsignmentReturn}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-returns/tomorrow"
              component={AdminTomorrowConsignmentReturn}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-returns"
              component={AdminTodayConsignmentReturn}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-dispatch"
              component={TodayConsignmentDispatch}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-collections"
              component={AdminTodayConsignmentCollection}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-collections/tomorrow"
              component={AdminTomorrowConsignmentCollection}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-collections/past"
              component={AdminPastConsignmentCollection}
            />
            <Route
              exact
              path="/admin/dashboard/consignment-collections/unprocessed"
              component={UnprocessedConsignmentCollection}
            />

            <Route
              exact
              path="/admin/dashboard/booking-collections"
              component={AdminTodayBookingCollection}
            />
            <Route
              exact
              path="/admin/dashboard/booking-collections/tomorrow"
              component={AdminTomorrowBookingCollection}
            />
            <Route
              exact
              path="/admin/dashboard/booking-collections/past"
              component={AdminPastBookingCollection}
            />
            <Route
              exact
              path="/admin/dashboard/booking-collections/unprocessed"
              component={UnprocessedBookingCollection}
            />

            <Route
              exact
              path="/admin/dashboard/booking-request"
              component={NewAdminBookingRequest}
            />
            <Route
              exact
              path="/admin/dashboard/booking-transfer"
              component={BookingTransfer}
            />

            <Route exact path="/admin/kits" component={AdminKits} />
            <Route exact path="/admin/sets" component={AdminSets} />
            <Route
              exact
              path="/admin/edit-sets/:id?"
              component={AdminSetsEdit}
            />
            <Route exact path="/admin/products" component={AdminProducts} />
            <Route exact path="/admin/categories" component={AdminCategories} />
            <Route exact path="/admin/users" component={AdminUsers} />
            <Route exact path="/admin/hospitals" component={AdminHospital} />
            <Route exact path="/admin/bookings" component={AdminBooking} />
            <Route
              exact
              path="/admin/bookings/close"
              component={AdminBookingClose}
            />
            <Route
              exact
              path="/admin/bookings/cancel"
              component={AdminBookingCancel}
            />
            <Route
              exact
              path="/admin/booking/add"
              component={AdminAddBooking}
            />
            <Route
              exact
              path="/admin/booking/edit/:id?"
              component={AdminEditBooking}
            />
            <Route
              exact
              path="/admin/booking/details/:id?"
              component={AdminDetailsBooking}
            />
            <Route
              exact
              path="/admin/bookings/consignment/close"
              component={AdminBookingCloseConsignment}
            />
            <Route
              exact
              path="/admin/bookings/consignment"
              component={AdminBookingConsignment}
            />
            <Route
              exact
              path="/admin/availability"
              component={AdminAvailability}
            />
            <Route exact path="/admin/surgeons" component={AdminSurgeon} />
            <Route
              exact
              path="/admin/booking/item-used/:f?/:id?"
              component={AdminItemUsed}
            />
            <Route path="/admin/holiday-list" component={HolidayList} />
            <Route path="/admin/operations" component={Operation} />

            <Route
              exact
              path="/admin/reports"
              component={AdminReportHistorical}
            />
            <Route
              exact
              path="/admin/reports/consignment"
              component={AdminReportConsignment}
            />
            <Route
              exact
              path="/admin/consignment-request"
              component={PendingConsignmentRequest}
            />
            <Route
              exact
              path="/admin/rejected-consignment-request"
              component={RejectConsignmentRequest}
            />
            <Route
              exact
              path="/admin/closed-consignment-request"
              component={CloseConsignmentRequest}
            />

            <Route exact path="/admin/archive" component={AdminArchive} />
            <Route
              exact
              path="/admin/bookings/booking"
              component={AdminBookingBooking}
            />

            <Route
              exact
              path="/reps/consignment-request"
              component={ConsignmentRequest}
            />
            <Route
              exact
              path="/reps/rejected-consignment-request"
              component={RejectedBooking}
            />
            <Route
              exact
              path="/reps/cancelled-consignment-request"
              component={CancelledBooking}
            />
            <Route
              exact
              path="/reps/consignment-request/edit/:id?"
              component={EditConsignmentRequest}
            />
            <Route
              exact
              path="/reps/consignment-request/add"
              component={AddConsignmentRequest}
            />
            <Route exact path="/reps/dashboard" component={RepsDashboard} />
            <Route exact path="/reps/open-booking" component={OpenBooking} />
            <Route
              exact
              path="/reps/consignment-booking"
              component={ConsignmentBooking}
            />
            <Route
              exact
              path="/reps/reject-booking"
              component={RejectBooking}
            />
            <Route
              exact
              path="/reps/closed-booking"
              component={ClosedBooking}
            />
            <Route
              exact
              path="/reps/cancel-booking"
              component={CancelBooking}
            />
            <Route exact path="/reps/booking/add" component={AddBooking} />
            <Route
              exact
              path="/reps/booking/edit/:id?"
              component={EditBooking}
            />
            <Route
              exact
              path="/reps/booking/details/:id?"
              component={DetailsBooking}
            />
            <Route
              exact
              path="/reps/consignment-request/details/:id?"
              component={DetailPage}
            />
            <Route
              exact
              path="/admin/consignment-request/details/:id?"
              component={BookingReview}
            />
            <Route exact path="/reps/hospitals" component={RepHospital} />
            <Route exact path="/reps/surgeons" component={RepSurgeon} />
            <Route
              exact
              path="/reps/booking/item-used/:f?/:id?"
              component={RepsItemUsed}
            />
            <Route exact path="/reps/item-used" component={RepsItemUsedList} />
            <Route
              exact
              path="/reps/item-used/tomorrow"
              component={RepsTomorrowItemUsedList}
            />
            <Route
              exact
              path="/reps/item-used/past"
              component={RepsPastItemUsedList}
            />
            <Route
              exact
              path="/reps/item-used/all"
              component={RepsAllItemUsedList}
            />

            <Route path="/reps/change-password" component={ChangePassword} />
            <Route path="/reps/edit-profile" component={EditProfile} />
            <Route path="/reps/operations" component={RepsOperation} />
          </div>
        </Router>
        </CacheBuster>
      </div>
    
    );
  }
}

export default connect()(App);
