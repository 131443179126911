export function register() {
    if ("serviceWorker" in navigator && process.env.NODE_ENV === "production") {
      navigator.serviceWorker.register("/service-worker.js").then((registration) => {
        console.log("Service Worker registered:", registration);
   
        // Listen for controller change and reload the page
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          window.location.reload();
        });
      }).catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
    } else {
      console.log("Service Worker not supported or not in production mode");
    }
  }
   